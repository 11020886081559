import type React from 'react';
import type { Tracker } from '@yoursurprise/segment-analytics';
import { IntlProvider } from 'react-intl';
import type { SearchClient } from 'algoliasearch';
import type { InstantSearchServerState } from 'react-instantsearch-core';
import TranslationProvider from '../../../general/Translation/TranslationProvider';
import WebshopContextProvider from '../../../general/WebshopContext/WebshopContextProvider';
import AlgoliaGlobalSearch from './AlgoliaGlobalSearch';
import type { AlgoliaGlobalSearchData } from '../../../../ssr/src/Outlet/AlgoliaGlobalSearch/AlgoliaGlobalSearchSchema';

interface AppProps {
    algoliaServerState?: InstantSearchServerState;
    searchClient: SearchClient;
    searchData: AlgoliaGlobalSearchData;
    tracker?: Tracker | undefined;
}

const App: React.FC<AppProps> = ({ algoliaServerState, searchClient, searchData, tracker }) => {
    const {
        categoryIndexName,
        contextData,
        currency,
        faqIndexName,
        faqUrl,
        isSearchPage,
        locale,
        productIndexName,
        querySuggestionsIndexName,
        translations,
    } = searchData;

    return (
        <IntlProvider locale={locale}>
            <TranslationProvider dictionary={translations}>
                <WebshopContextProvider data={contextData}>
                    <AlgoliaGlobalSearch
                        algoliaServerState={algoliaServerState}
                        categoryIndexName={categoryIndexName}
                        currency={currency}
                        faqIndexName={faqIndexName}
                        faqUrl={faqUrl}
                        isSearchPage={isSearchPage}
                        productIndexName={productIndexName}
                        querySuggestionsIndexName={querySuggestionsIndexName}
                        searchClient={searchClient}
                        tracker={tracker}
                    />
                </WebshopContextProvider>
            </TranslationProvider>
        </IntlProvider>
    );
};

export default App;
