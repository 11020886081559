import { Tracker } from '@yoursurprise/segment-analytics';
import { BrowserRouter } from 'react-router-dom';
import { hydrateRoot } from 'react-dom/client';
import { document, window } from '../../../js/globals';
import App from './Components/App';
import { createSearchClient } from '../general/searchClient';

const container = (document as Document).getElementById('algolia-global-search');

const { ALGOLIA_GLOBAL_SEARCH_DATA } = window as Window;

if (container && ALGOLIA_GLOBAL_SEARCH_DATA) {
    const searchClient = createSearchClient(
        ALGOLIA_GLOBAL_SEARCH_DATA.appID,
        ALGOLIA_GLOBAL_SEARCH_DATA.searchApiKey,
    );

    hydrateRoot(
        container,
        <BrowserRouter>
            <App
                algoliaServerState={{ initialResults: {} }}
                searchClient={searchClient}
                searchData={ALGOLIA_GLOBAL_SEARCH_DATA}
                tracker={new Tracker()}
            />
        </BrowserRouter>,
    );
}
